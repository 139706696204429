// For Landing header

.header {
  position: sticky;
  top: 0;
  left: 0;
}

.header--detached {
  transform: translateY(-100%);
}

.header--sticky {
  transform: translateY(0);
  transition: box-shadow 0.2s ease 0.2s, transform 0.2s ease;
}

.header--unsticky {
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.twitt-item {
  padding: 0 9px;
}

.running-line {
  width: calc(100% + 20px);
  position: relative;
  overflow: hidden;
  outline: 1px solid transparent;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  margin-left: -10px;

  &_text {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: ticker 80s infinite linear forwards;

    @media only screen and (max-width: 768px) {
      animation: ticker 50s infinite linear forwards;
    }

    @media only screen and (max-width: 576px) {
      animation: ticker 20s infinite linear forwards;
    }

    span {
      white-space: nowrap;
      margin-right: 10px;

      @media only screen and (max-width: 576px) {
        font-size: inherit;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    height: 58px;
  }
}

@keyframes ticker {
  0% {
    transform: translate(50%, 0);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}
@keyframes heartbeat {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 9px 197.5px -47.4px rgba(105, 46, 171, 0.25);

  .bullet-container {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .bullet {
      margin-right: 14px;

      &:last-child {
        margin-right: 0px;
      }

      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: white;
      opacity: 0.5;

      &.active {
        opacity: 1;
      }
    }
  }

  .carousel-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .carousel-single {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      z-index: 0;
      left: 0;
      top: 30px;
      width: 100%;
      height: 100%;
      transition: z-index 0ms 250ms;

      .carousel-single-image {
        position: relative;
        left: 0;
        top: 0;
        transition: 500ms cubic-bezier(0.17, 0.67, 0.55, 1.43);
        transform: translateX(0%) scale(0);
        opacity: 0;
        border-radius: 100%;

        &-main {
          border-radius: 100%;
        }

        &-icon {
          position: absolute;
          bottom: 8px;
          right: 5px;
          opacity: 0;
          transform: translateX(0%) scale(0);
          transition: 500ms cubic-bezier(0.17, 0.67, 0.55, 1.43);
        }
      }

      .carousel-single-download {
        position: absolute;
        display: block;
        right: -22px;
        bottom: 12px;
        padding: 15px;
        color: #333333;
        background-color: #fdc84b;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
        transition: 500ms cubic-bezier(0.17, 0.67, 0.55, 1.43);
        opacity: 0;

        &:hover,
        &:focus {
          outline: none;
          text-decoration: none;
        }
      }

      .carousel-single-description {
        max-width: 660px;
        display: block;
        float: left;
        font-size: 16px;
        font-weight: 400;
        transition: 500ms cubic-bezier(0.17, 0.67, 0.55, 1.43);
        opacity: 0;
        margin-top: 16px;
        line-height: 24px;
      }

      .carousel-single-author {
        display: flex;
        flex-direction: column;
        float: right;
        transition: 500ms cubic-bezier(0.17, 0.67, 0.55, 1.43);
        opacity: 0;
        margin-top: 24px;

        p {
          display: inline-block;
          vertical-align: middle;
          margin: 0;
        }

        &-name {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          background: linear-gradient(108.17deg, #c57bff 44.81%, #305dff 100%);
          color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
          display: initial;
        }

        &-position {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          opacity: 0.5;
        }

        &:hover,
        &:focus {
          outline: none;
          text-decoration: none;
        }
      }

      &.preactivede {
        //z-index: 0;
        .carousel-single-image {
          //opacity: 0;
          transform: translateX(-50%) scale(0);
        }
      }

      &.preactive {
        z-index: 1;

        .carousel-single-image {
          opacity: 0.8;
          transform: translateX(-65%) scale(0.8);
        }

        .carousel-single-download {
          transform: translateX(-150px);
        }

        .carousel-single-description {
          transform: translateX(-150px);
        }

        .carousel-single-author {
          transform: translateX(-150px);
        }
      }

      &.proactive {
        z-index: 1;

        .carousel-single-image {
          opacity: 0.8;
          transform: translateX(65%) scale(0.8);
        }

        .carousel-single-download {
          transform: translateX(150px);
        }

        .carousel-single-description {
          transform: translateX(150px);
        }

        .carousel-single-author {
          transform: translateX(150px);
        }
      }

      &.proactivede {
        //z-index: 0;
        .carousel-single-image {
          //opacity: 0;
          transform: translateX(50%) scale(0);
        }
      }

      &.active {
        z-index: 2;

        .carousel-single-image {
          opacity: 1;
          transform: translateX(0%) scale(1);

          &-icon {
            opacity: 1;
            transform: translateX(0%) scale(1);
          }
        }

        .carousel-single-download {
          opacity: 1;
          transition-delay: 100ms;
          transform: translateX(0px);
        }

        .carousel-single-description {
          opacity: 1;
          transition-delay: 200ms;
          transform: translateX(0px);
        }

        .carousel-single-author {
          opacity: 1;
          transition-delay: 300ms;
          transform: translateX(0px);

          i {
            animation-name: heartbeat;
            animation-duration: 500ms;
            animation-delay: 900ms;
            animation-interation: 1;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }

  .carousel-left {
    position: absolute;
    z-index: 3;
    display: block;
    right: 85%;
    top: 50%;
    color: #ffffff;
    transform: translateY(-50%);
    padding: 20px 15px;
    border-top: 2px solid #fdc84b;
    border-right: 2px solid #fdc84b;
    border-bottom: 2px solid #fdc84b;
    border-left: 2px solid #fdc84b;
    margin-right: -2px;
  }

  .carousel-right {
    position: absolute;
    z-index: 3;
    display: block;
    left: 85%;
    top: 50%;
    color: #ffffff;
    transform: translateY(-50%);
    padding: 20px 15px;
    border-top: 2px solid #fdc84b;
    border-right: 2px solid #fdc84b;
    border-bottom: 2px solid #fdc84b;
    border-left: 2px solid #fdc84b;
    margin-left: -2px;
  }

  .not-visible {
    display: none !important;
  }
}
.join-us_cards {
  &-card {
    transition: all 0.3s ease;
    transform: translateY(90px);

    p,
    button {
      opacity: 0;
      transition: all 0.3s ease;
    }

    &:hover {
      transform: translateY(0px);

      p,
      button {
        opacity: 1;
      }
    }

    @media only screen and (max-width: 768px) {
      transform: none;
      border-radius: 16px;

      p,
      button {
        opacity: 1;
      }
    }
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;
}

.header--detached {
  transform: translateY(-100%);
}

.header--sticky {
  transform: translateY(0);
  transition: box-shadow 0.2s ease 0.2s, transform 0.2s ease;
}

.header--unsticky {
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}




